<template>
  <TransitionGroup
    class="container"
    tag="div"
    name="list"
  >
    <div
      v-if="isEditable"
      key="header"
      class="container__edit-header"
    >
      <div class="edit-header__changed">
        <span>
          {{
            $t('settings.workspace.renaming.updatedRenamings', {
              count: updatedRenamings.length
            })
          }}
        </span>
      </div>
      <div class="edit-header__actions">
        <span
          class="actions__cancel"
          @click="cancelEdition"
        >
          {{ $t('settings.usersPermissions.dataSensitivity.table.cancel') }}
        </span>
        <ReflectButton
          class="edit-header__save-button"
          :disabled="!hasUnsavedModification"
          @click="saveRenamings"
        >
          {{ $t('settings.workspace.renaming.saveRenamings') }}
        </ReflectButton>
      </div>
    </div>

    <div
      key="table"
      class="container__module"
    >
      <div
        v-if="!!$slots.illustration"
        class="module__illustration"
      >
        <slot
          name="illustration"
          :illustration-renamings="editableRenamingFields"
        />
      </div>

      <ReflectTable
        :columns="columns"
        :with-vertical-borders="false"
        :without-full-height-scroll="true"
      >
        <tr
          v-for="renaming in editableRenamingFields"
          :key="renaming.field"
        >
          <td class="table__cell">
            {{ getRenamingFieldTranslation(renaming.field, true) }}
          </td>
          <td class="table__cell table__cell--second">
            {{ getFieldDefinitionTranslation(renaming.field) }}
          </td>
          <td class="table__cell table__cell--last">
            <ReflectField
              v-model="renaming.naming"
              :with-border="false"
              :is-read-only="!isEditable"
              :name="renaming.field"
              type="text"
              @blur="() => (renaming.naming = formatNaming(renaming.naming))"
            />
          </td>
        </tr>
      </ReflectTable>
    </div>
  </TransitionGroup>
</template>

<script lang="ts">
import { useToast } from 'vue-toastification'
import type { SlotsType } from 'vue'
import { UPDATE_RENAMINGS_MUTATION } from '~/gql/renamings/updateRenamings'
import { useRouterStore } from '~/stores/router'
import { capitalizeFirstLetter } from '~/services/textFormat'
import type { Renaming, RenamingField } from '~/types/renaming'
import { RENAMING_FIELD_DEFINITION_KEY } from '~/constants/renaming'
import { getRenamingFieldTranslation } from '~/services/explore'

const RENAMINGS_TABLE_COLUMNS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  RENAMING: 'renaming'
} as const

export default {
  name: 'RenamingsEditor',
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    initialRenamings: {
      type: Array as PropType<Renaming[]>,
      required: true
    }
  },
  slots: Object as SlotsType<{
    illustration: { illustrationRenamings: Renaming[] }
  }>,
  emits: ['cancel', 'updated'],
  setup() {
    return { getRenamingFieldTranslation }
  },
  data(): {
    editableRenamingFields: Renaming[]
  } {
    return {
      editableRenamingFields: []
    }
  },
  computed: {
    columns() {
      return [
        {
          key: RENAMINGS_TABLE_COLUMNS.NAME,
          value: this.$t('settings.workspace.renaming.table.fieldName')
        },
        {
          key: RENAMINGS_TABLE_COLUMNS.DESCRIPTION,
          value: this.$t('settings.workspace.renaming.table.description')
        },
        {
          key: RENAMINGS_TABLE_COLUMNS.RENAMING,
          value: this.$t('settings.workspace.renaming.table.newName')
        }
      ]
    },
    hasUnsavedModification() {
      return this.updatedRenamings.length > 0
    },
    updatedRenamings() {
      return this.editableRenamingFields.filter(renaming => {
        const initialRenaming = this.initialRenamings!.find(
          initialRenaming => renaming.field === initialRenaming.field
        )
        return (
          initialRenaming &&
          this.formatNaming(initialRenaming.naming) !==
            this.formatNaming(renaming.naming)
        )
      })
    }
  },
  watch: {
    hasUnsavedModification: {
      handler(newValue) {
        const { setHasUnsavedModification } = useRouterStore()
        if (newValue) {
          setHasUnsavedModification(true)
        } else {
          setHasUnsavedModification(false)
        }
      }
    }
  },
  created() {
    this.initiateEditableRenamings()
  },
  methods: {
    cancelEdition() {
      this.$emit('cancel')
      this.initiateEditableRenamings()
    },
    async saveRenamings() {
      const renamingsToUpdate = this.updatedRenamings.map(
        ({ field, naming }) => ({
          field,
          naming
        })
      )

      try {
        const {
          data: { updateRenamings }
        } = await this.$apiGqlClient.mutate({
          mutation: UPDATE_RENAMINGS_MUTATION,
          variables: { renamings: renamingsToUpdate }
        })

        if (updateRenamings) {
          const toast = useToast()
          toast.success(this.$t('settings.toaster.success'))
          this.$emit('updated', this.editableRenamingFields)
        }
      } catch (error) {
        const toast = useToast()
        toast.error(this.$t('settings.toaster.error'))
        throw error
      }
    },
    initiateEditableRenamings() {
      this.editableRenamingFields = [
        ...this.initialRenamings.map(renaming => ({
          ...renaming
        }))
      ]
    },
    getFieldDefinitionTranslation(field: RenamingField) {
      const translationKey = RENAMING_FIELD_DEFINITION_KEY[field]
      return translationKey ? this.$t(translationKey) : ''
    },
    formatNaming(naming: string) {
      return capitalizeFirstLetter(naming.trim())
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;

  &__edit-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $margin-regular 0;

    .edit-header {
      &__changed {
        display: flex;
        flex-direction: column;
        margin-right: $margin-small;
        color: $text-primary;
        text-align: right;
        gap: 2px;
        @include font-text;
        @include font-size($font-size-mini);
      }

      &__actions {
        display: flex;
        align-items: center;
        gap: $margin-small;
        @include font-text;
        @include font-size($font-size-regular);

        .actions {
          &__cancel {
            cursor: pointer;
          }

          &__cancel:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__module {
    box-sizing: border-box;
    height: 100%;
    background-color: $bg-secondary;
    border-radius: 8px;
    overflow: auto;
    @include block-shadow;

    .table {
      &__cell {
        @include font-size($font-size-mini);
        height: 32px;
        box-sizing: content-box;
        padding: $margin-mini;
        padding-left: $margin-regular;
        white-space: nowrap;
        @include font-text;
        vertical-align: middle;
        overflow-x: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: $text-primary;

        &--second {
          white-space: initial;
          color: $text-tertiary;
          margin: auto;
        }

        &--last {
          padding-right: $margin-small;
        }
      }
    }

    .module {
      &__illustration {
        display: flex;
        align-items: center;
        padding: $margin-mini $margin-regular;
        height: 400px;
      }
    }
  }
}
</style>
